import { useState } from "react";
import { Typography, Grid } from "@mui/material";
import ServiceCard from "../../components/serviceCard/ServiceCard";
import { services } from "../../constans/services";

const Services = () => {
  const [expandedIds, setExpandedIds] = useState([]);

  const handleExpaned = (id) => {
    setExpandedIds(expandedIds.includes(id) ? expandedIds.filter((cardId) => cardId !== id) : [id, ...expandedIds]);
  };

  return (
    <>
      <Typography variant="h4" align="center" mb={5}>
        Získajte svoj 3D výtlačok alebo 3D sken rýchlo a v najlepšej kvalite.
      </Typography>
      <Typography variant="body1" align="center" mb={5}>
        Máte nápad, designový návrh alebo len potrebujete náhradnú súčiastku, ktorá sa Vám poškodila? Sme tu pre Vás a pomôžeme
        Vám, dostať to všetko do fyzickej podoby.
      </Typography>
      <Typography variant="h4" align="center" mb={5}>
        Čomu sa venujeme
      </Typography>

      <Grid container spacing={3} mb={5}>
        {services.map(({ id, image, heading, text, buttonText, chip, chipText, description }) => (
          <Grid key={id} item xs={12} sm={6} md={4}>
            <ServiceCard
              image={image}
              heading={heading}
              text={text}
              buttonText={buttonText}
              chip={chip}
              chipText={chipText}
              expandedIds={expandedIds}
              handleExpaned={handleExpaned}
              id={id}
              description={description}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Services;
