import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import PaletteIcon from "@mui/icons-material/Palette";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import HandymanIcon from "@mui/icons-material/Handyman";

const settings = {
  fontSize: "large",
  color: "error",
};

export const productsUsage = [
  {
    id: 1,
    icon: <MedicalInformationIcon {...settings} />,
    description: "Medicína",
  },
  {
    id: 2,
    icon: <PaletteIcon {...settings} />,
    description: "Výtvarníctvo",
  },
  {
    id: 3,
    icon: <PrecisionManufacturingIcon {...settings} />,
    description: "Priemyselná automatizácia",
  },
  {
    id: 4,
    icon: <SettingsBackupRestoreIcon {...settings} />,
    description: "Rekonštrukcia predmetov",
  },
  {
    id: 5,
    icon: <SportsEsportsIcon {...settings} />,
    description: "Hobby",
  },
  {
    id: 6,
    icon: <HandymanIcon {...settings} />,
    description: "Domáce dielne",
  },
];
