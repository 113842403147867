import { Stack, Typography } from "@mui/material";

const ImageDescription = ({ image }) => (
  <Stack>
    <Typography variant="caption">
      <strong>Technológia: </strong>
      {image.technology}
    </Typography>
    <Typography variant="caption">
      <strong>Materiál: </strong>
      {image.material}
    </Typography>
    <Typography variant="caption">
      <strong>Výška vrstvy: </strong>
      {image.layer}
    </Typography>
    <Typography variant="caption">
      <strong>Rozmery: </strong>
      {image.dimensions}
    </Typography>
    <Typography variant="caption">
      <strong>Popis: </strong>
      {image.description}
    </Typography>
  </Stack>
);

export default ImageDescription;
