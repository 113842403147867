import { Box, Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const ReviewRating = ({ rating }) => (
  <Box
    sx={{
      width: 200,
      display: "flex",
      alignItems: "center",
    }}
    my={2}
  >
    <Rating value={rating} readOnly emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
  </Box>
);

export default ReviewRating;
