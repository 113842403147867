export const options1 = [
  {
    value: "PLA",
    label: "PLA",
  },
  {
    value: "PETG",
    label: "PETG",
  },
  {
    value: "ABS",
    label: "ABS",
  },
  {
    value: "PC",
    label: "PC",
  },
  {
    value: "PA (Nylon)",
    label: "PA (Nylon)",
  },
];

export const options2 = [
  {
    value: "Tvrdý resin",
    label: "Tvrdý resin",
  },
  {
    value: "Flexibilný resin",
    label: "Flexibilný resin",
  },
];

export const options3 = [
  {
    value: "",
    label: "",
  },
];

export const noteDescription = `Aby sme Vám vedeli čo najlepšie poradiť, do poznámky nám napíšte, požiadavky na presnosť alebo pevnosť poprípade či bude komponent nejako
namáhaný, alebo vystaveny vonkajším vplyvom. Tak isto nám v poznámke môžete nechať informácie o požadovanom počte kusov, farbe materiálu, termíne dodania a podobne.`;
