import { useContext } from "react";
import { useTheme } from "@emotion/react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { Container } from "@mui/material";
import { ColorModeContext } from "../../context/ColorModeContext";

const Layout = ({ children }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <Container>
      <Navbar theme={theme} enableDarkMode={colorMode.toggleColorMode} />
      {children}
      <Footer />
    </Container>
  );
};

export default Layout;
