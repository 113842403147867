import { useState } from "react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedinIcon from "@mui/icons-material/LinkedIn";
import Chip from "@mui/material/Chip";

const anchorStyle = {
  color: "inherit",
};

const PhoneButton = ({ setIsVisible }) => {
  const handleVisible = () => {
    setIsVisible((prevState) => !prevState);
  };

  return (
    <Button size="small" color="error" sx={{ width: "fit-content" }} onClick={handleVisible}>
      Zobraziť číslo
    </Button>
  );
};

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Divider>
        <Chip label="KONTAKT" />
      </Divider>
      <Box sx={{ width: "100%" }} mt="1rem">
        <Stack spacing={1} alignItems="center">
          <Typography textAlign="center">Ing. Vladimír Jakubech</Typography>
          {isVisible ? <Typography textAlign="center">+421 944 537 635</Typography> : <PhoneButton setIsVisible={setIsVisible} />}

          <Typography textAlign="center">info@jakutech.sk</Typography>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="center" mt="2rem" mb={3}>
          <a href="https://www.facebook.com/profile.php?id=100086667992154" target="_blank" rel="noreferrer" style={anchorStyle}>
            <FacebookIcon />
          </a>
          <a href="https://www.instagram.com/jakutech3d/" target="_blank" rel="noreferrer" style={anchorStyle}>
            <InstagramIcon />
          </a>
          <a href="https://www.youtube.com/channel/UCyHOThPgWELfbobLLYo_VsA" target="_blank" rel="noreferrer" style={anchorStyle}>
            <YouTubeIcon />
          </a>
          <a href="https://www.linkedin.com/in/vladim%C3%ADr-jakubech" target="_blank" rel="noreferrer" style={anchorStyle}>
            <LinkedinIcon />
          </a>
        </Stack>
      </Box>
    </>
  );
};

export default Footer;
