import * as yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const schema = yup.object().shape({
  files: yup.mixed().test("fileSize", "Veľkosť súboru presiahla 10MB", (files, { createError }) => {
    const fileArray = Object.values(files);

    // Check if all file size together is lower tahn 10MB
    const sumFilesSize = fileArray.reduce((acc, curr) => acc + curr.size, 0) > 10000000;

    if (sumFilesSize) {
      return createError({ message: "Veľkosť súborov presiahla 10MB" });
    }

    return files;
  }),
  firstName: yup
    .string()
    .required("Krstné meno je povinné pole")
    .min(2, "Krstné meno je príliš krátke")
    .max(255, "Krstné meno je príliš dlhé"),
  lastName: yup
    .string()
    .required("Priezvisko je povinné pole")
    .min(2, "Priezvisko je príliš krátke")
    .max(255, "Priezvisko je príliš dlhé"),
  email: yup
    .string()
    .email(
      "Email je v zlom formáte",
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    .required("Email je povinné pole"),
  phoneNumber: yup
    .string()
    .required("Telefón je povinne pole")
    .matches(phoneRegExp, "Telefónne číslo je v zlom formáte")
    .min(5, "Číslo je príliš krátke")
    .max(13, "Číslo je príliš dlhé"),
  streetNumber: yup.string().required("Číslo ulice je povinné pole"),
  postalCode: yup
    .string()
    .required("PSČ je povinné pole")
    .min(5, "PSČ musí obsahovať 5 číslic")
    .max(5, "PSČ musí obsahovať 5 číslic"),
  city: yup
    .string()
    .required("Mesto je povinné pole")
    .min(3, "Názov mesta je príliš krátky")
    .max(255, "Názov mesta je príliš dlhý"),
});
