export const navItems = [
  {
    id: 4,
    name: "O nás",
    path: "o_nas",
  },
  {
    id: 1,
    name: "Služby",
    path: "sluzby",
  },
  {
    id: 2,
    name: "Galéria",
    path: "galeria",
  },
  {
    id: 3,
    name: "Recenzie",
    path: "recenzie",
  },
];
