import { Typography } from "@mui/material";
import ReviewList from "../../components/review/ReviewList";

const Reviews = () => {
  return (
    <>
      <Typography variant="h4" mb={5} align="center">
        Recenzie
      </Typography>
      <ReviewList />
    </>
  );
};

export default Reviews;
