import { Box, Typography } from "@mui/material";

const SliderIcon = ({ description, icon }) => (
  <Box textAlign="center">
    {icon}
    <Typography variant="body1">{description}</Typography>
  </Box>
);

export default SliderIcon;
