import { Box, Grid, Typography } from "@mui/material";
import notFound from "../../assets/404-not-found.webp";

const NotFound = () => (
  <Box sx={{ flexGrow: 1 }} mb={5}>
    <Grid container spacing={5} alignItems="center">
      <Grid item xs={12} md={6}>
        <Typography variant="h3" textAlign="center" gutterBottom>
          Ups. Stránka sa nenašla
        </Typography>
        <Typography textAlign="center">Pokračujte do jednej zo sekcií zobrazených v navigácií.</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ width: "100%" }}>
          <img src={notFound} alt="not found" style={{ width: "100%" }} />
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default NotFound;
