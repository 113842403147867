export const reviews = [
  {
    id: 1,
    name: "PAMA Photo",
    position: "Fotograf",
    avatar: "",
    service: "3D tlač kľúčeniek",
    review: `"Skvelý prístup, rýchle jednanie, detaily v oboch položkách, 
       ktoré som chcel tlačiť boli komunikované v podstate v deň odoslania 
       požiadavky na stránku. Vytlačené veci som mal v rukách do 3 dni. Odporúčam :)"`,
    date: new Date(),
    rating: 5,
  },
];
