import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Chip, Collapse } from "@mui/material";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const ExpandMore = styled((props) => {
  const { expand, isDisabled, ...other } = props;
  return <IconButton disabled={isDisabled} {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ServiceCard = ({ id, image, heading, text, buttonText, chip, chipText, expandedIds, handleExpaned, description }) => {
  return (
    <Card>
      <CardActionArea>
        <CardMedia component="img" height="250" image={image} alt="green iguana" onClick={() => !chip && handleExpaned(id)} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {heading}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ marginBottom: "0px" }}>
        {chip ? (
          <Chip label={chipText} color="error" />
        ) : (
          <Button component={NavLink} to="../objednavka" size="small" color="primary" variant="outlined">
            {buttonText}
          </Button>
        )}

        <ExpandMore
          expand={expandedIds.includes(id)}
          aria-expanded={expandedIds.includes(id)}
          aria-label="show more"
          onClick={() => handleExpaned(id)}
          isDisabled={chip}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expandedIds.includes(id)} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph variant="caption">
            {description.first}
          </Typography>
          <Typography paragraph variant="caption">
            {description.second}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ServiceCard;
