import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { IconButton } from "@mui/material";

const DarkModeIcon = ({ enableDarkMode, theme }) => (
  <IconButton sx={{ mr: 2 }} onClick={enableDarkMode} color="inherit">
    {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
  </IconButton>
);

export default DarkModeIcon;
