import { Box, Button, Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import BeforeAfterWhite from "../../components/beforeAfterImage/BeforeAfterWhite";
import BeforeAfterDark from "../../components/beforeAfterImage/BeforeAfterDark";

const Home = () => {
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }} mb={5}>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12} md={5} sx={{ textAlign: { xs: "center", md: "left" } }}>
          <Typography variant="h4" style={{ textTransform: "uppercase" }} gutterBottom>
            Potenciál 3D tlačiarní
          </Typography>
          <Typography mb={5}>Len pár klikov od návrhu k vášmu hotovému produktu</Typography>
          <Button component={NavLink} to="objednavka" variant="contained" color="error">
            Nezáväzná požiadavka
          </Button>
        </Grid>
        <Grid item xs={12} md={7}>
          {theme.palette.mode === "dark" ? <BeforeAfterDark /> : <BeforeAfterWhite />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
