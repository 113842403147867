import CreateIcon from "@mui/icons-material/Create";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

const settings = {
  fontSize: "large",
  color: "error",
};

export const orderSteps = [
  {
    id: 1,
    icon: <CreateIcon {...settings} />,
    description: "Vyplň formulár",
  },
  {
    id: 2,
    icon: <CloudUploadIcon {...settings} />,
    description: "Nahraj obrázok",
  },
  {
    id: 3,
    icon: <MarkEmailReadIcon {...settings} />,
    description: "Odošli formulár",
  },
];
