import p1 from "../assets/1.jpg";
import p2 from "../assets/2.jpg";
import p3 from "../assets/3.jpg";
import p4 from "../assets/4.jpg";
import p5 from "../assets/5.jpg";
import p6 from "../assets/6.jpg";
import p7 from "../assets/7.jpg";
import p8 from "../assets/8.jpg";
import p9 from "../assets/9.jpg";
import p10 from "../assets/10.jpg";
import p11 from "../assets/11.jpg";
import p12 from "../assets/12.jpg";
import p13 from "../assets/13.jpg";
import p14 from "../assets/14.jpg";
import p15 from "../assets/15.jpg";
import p16 from "../assets/16.jpg";
import p17 from "../assets/17.jpg";
import p18 from "../assets/18.jpg";
import p19 from "../assets/19.jpg";
import p20 from "../assets/20.jpg";
import p21 from "../assets/21.jpg";
import p22 from "../assets/22.jpg";

export const productsImages = [
  {
    id: 1,
    src: p1,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA Silver Metalic",
    layer: "0.15 [mm]",
    dimensions: "150x110x100 [mm]",
    description: "Busta hernej postavy Oddworld Abe's Exoddus",
  },
  {
    id: 2,
    src: p2,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA Silver Metalic",
    layer: "0.15 [mm]",
    dimensions: "150x110x100 [mm]",
    description: "Aplikovany primer. Busta hernej postavy Oddworld Abe's Exoddus",
  },
  {
    id: 3,
    src: p3,
    technology: "FDM (Prusa MK3S+)",
    material: "Prusament PLA Jet Black",
    layer: "0.15 [mm]",
    dimensions: "220x210x220 [mm]",
    description: "Figúrka Batmana spájaná z viacerých časti ",
  },
  {
    id: 4,
    src: p4,
    technology: "FDM (Prusa MK3S+)",
    material: "Prusament PLA Jet Black",
    layer: "0.15 [mm]",
    dimensions: "220x210x220 [mm]",
    description: "Figúrka Batmana spájaná z viacerých časti ",
  },
  {
    id: 5,
    src: p5,
    technology: "FDM (Prusa MK3S+)",
    material: "Prusament PETG Orange",
    layer: "0.15 [mm]",
    dimensions: "35x40x30 [mm]",
    description: "Kotviace diely pre cable Management",
  },
  {
    id: 6,
    src: p6,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker ABS Grey",
    layer: "0.15 [mm]",
    dimensions: "40x40x30 [mm]",
    description: "Stojan na sklenenú guľu (lakovaný čiernou farbou)",
  },
  {
    id: 7,
    src: p7,
    technology: "FDM (Prusa MK3S+)",
    material: "PLA white",
    layer: "0.15 [mm]",
    dimensions: "200x60x80 [mm]",
    description: "Stojan na hodinky",
  },
  {
    id: 8,
    src: p8,
    technology: "FDM (Prusa MK3S+)",
    material: "PLA Green/red",
    layer: "0.15 [mm]",
    dimensions: "D=90, L=130 [mm]",
    description: "Dummy ukážkový hriadeľ s funkčným závitom na skrutku, drážkou pre pero a drážkou pre o-krúžok",
  },
  {
    id: 9,
    src: p9,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA Silver Metalic",
    layer: "0.15 [mm]",
    dimensions: "20x20x20 [mm]",
    description: "Kalibračná kocka (naše tlačiarne sú odladené do takéhoto stavu)",
  },
  {
    id: 10,
    src: p10,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA black",
    layer: "0.15 [mm]",
    dimensions: "270x220x140 [mm]",
    description: "Busta TMNT Ninja korytnačky (pre predstavu veľkositi priložená 2,-€ minca)",
  },
  {
    id: 11,
    src: p11,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA black",
    layer: "0.15 [mm]",
    dimensions: "D=50, L=120 [mm]",
    description: "Designová tuba na mince",
  },
  {
    id: 12,
    src: p12,
    technology: "FDM (Prusa MK3S+)",
    material: "Prusament PLA Jet Black",
    layer: "0.15 [mm]",
    dimensions: "220x160x100 [mm]",
    description: "Maska (lakované) ",
  },
  {
    id: 13,
    src: p13,
    technology: "FDM (Prusa MK3S+)",
    material: "PLA White",
    layer: "0.15 [mm]",
    dimensions: "90x100x100 [mm]",
    description: "Dekoračný pohár",
  },
  {
    id: 14,
    src: p14,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA Silver Metalic",
    layer: "0.15 [mm]",
    dimensions: "70x60x50 [mm]",
    description: "Úložné prepravky na batérie AAA / AA",
  },
  {
    id: 15,
    src: p15,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA Black",
    layer: "0.15 [mm]",
    dimensions: "130x130x150 [mm]",
    description: "Odkladací rack (striekaný)",
  },
  {
    id: 16,
    src: p16,
    technology: "FDM (Ultimaker 2+)",
    material: "ltimaker PLA Black",
    layer: "0.15 [mm]",
    dimensions: "40x40x50 [mm]",
    description: "Doplnky pre stolové hry (maľované) ",
  },
  {
    id: 17,
    src: p17,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA Black",
    layer: "0.15 [mm]",
    dimensions: "40x40x50 [mm]",
    description: "Doplnky pre stolové hry",
  },
  {
    id: 18,
    src: p18,
    technology: "FDM (Prusa MK3S+)",
    material: "PLA red/black/white",
    layer: "0.15 [mm]",
    dimensions: "240x170x120 [mm]",
    description: "Maska Spider-man lepená z viacerých dielov",
  },
  {
    id: 19,
    src: p19,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA Silver Metalic",
    layer: "0.15 [mm]",
    dimensions: "100x100x100 [mm]",
    description: "Hlava pre 3D tlačiarne (do hlavice je osádzaná tryska, chladenie a senzor polohy)",
  },
  {
    id: 20,
    src: p20,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA Silver Metalic",
    layer: "0.15 [mm]",
    dimensions: "D=80, L=70 [mm]",
    description: "Dummy hriadeľ pre turbínu",
  },
  {
    id: 21,
    src: p21,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker PLA Black",
    layer: "0.15 [mm]",
    dimensions: "130x130x100 [mm]",
    description: "Dummy ukážkový komponent s funkčným závitom",
  },
  {
    id: 22,
    src: p22,
    technology: "FDM (Ultimaker 2+)",
    material: "Ultimaker ABS Grey",
    layer: "0.15 [mm]",
    dimensions: "200x40x40; 50x50x40 [mm]",
    description: "Komponenty pre uchytenie ovládačov virtuálnej reality",
  },
];
