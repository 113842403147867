import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import ReviewRating from "./ReviewRating";

const ReviewCard = ({ review }) => (
  <Card sx={{ maxWidth: 275, margin: "0 auto", height: "100%", textAlign: "left" }}>
    <CardContent>
      <Box display="flex" alignItems="center">
        <Avatar src={review.avatar} alt={review.name} />
        <Box ml={2}>
          <Typography variant="subtitle2">{review.name}</Typography>
          <Typography variant="caption">{review.position}</Typography>
        </Box>
      </Box>
      <ReviewRating rating={review.rating} />
      <Typography variant="body2" mb={2} fontWeight="bold">
        Služba: {review.service}
      </Typography>
      <Typography variant="body2">{review.review}</Typography>
    </CardContent>
  </Card>
);

export default ReviewCard;
