import { Grid, Typography } from "@mui/material";
import ReviewList from "../../components/review/ReviewList";
import ReviewSlider from "../../components/reviewSlider/ReviewSlider";
import { reviews } from "../../constans/reviews";
import p9 from "../../assets/9.jpg";
import { NavLink } from "react-router-dom";

const About = () => (
  <>
    <Typography variant="h4" mb={5} align="center">
      O nás
    </Typography>
    <Grid container spacing={2} mb={5}>
      <Grid item xs={12} md={4}>
        <img src={p9} alt="cube" width="100%" />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body1">
          3D technológie sú kapitola, ktorá nás baví a žijeme s ňou už niekoľko rokov. Počnúc štúdiom na strednej a vysokej škole,
          prácou ako konštruktér pre firmu, ktorá patrí svetovú špičku a neskôr na v automotív sfére pri vývoji produktov a
          výrobných liniek sme zbierali skúsenosti, ktoré sa práve teraz snažíme využiť pri službách, ktoré Vám ponúkame.
        </Typography>
        <br />
        <Typography variant="body1">
          V súčasnosti sa zameriavame výhradne na výrobu komponentov pomocou dvoch technológii 3D tlačiarni (FDM,SLA) a využívame
          3D skenovanie ako nástroj pre reverzné inžinierstvo. Rukami nám prešlo niekoľko typov 3D tlačiarni a 3D skenerov od
          svetových výrobcov ako sú Ultimaker, Prusa, Creality, Anycubic a podobne. Náš výrobný arzenál je pripravený vyrábať
          radovo v desiatkach až stovkách kusov komponentov mesačne, v závislosti od veľkosti rozmerov produktu.
        </Typography>
        <br />
        <Typography variant="body1">
          Detailnejší popis technológii, ktoré sme spomenuli nájdete po kliknutí
          <NavLink to="/sluzby" style={{ color: "red", fontWeight: "bold", textDecoration: "none" }}>
            {" "}
            sem{" "}
          </NavLink>
          a po zvolení jednotlivej služby o ktorú máte záujem.
        </Typography>
      </Grid>
    </Grid>
    <Typography variant="h6" mb={2} align="center">
      Recenzie
    </Typography>
    {reviews.length < 3 ? <ReviewList /> : <ReviewSlider />}
  </>
);

export default About;
