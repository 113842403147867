import Slider from "react-slick";
import { useTheme } from "@emotion/react";
import ReviewCard from "../../components/review/ReviewCard";
import { reviews } from "../../constans/reviews";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ReviewSlider = () => {
  const theme = useTheme();

  const switchDotsColor = (themeMode) => {
    if (themeMode === "dark") {
      return "#fff";
    } else {
      return "#121212";
    }
  };

  const settings = {
    // adaptiveHeight: true,
    arrows: false,
    dots: true,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 400,
    appendDots: (dots) => (
      <div style={{ position: "relative" }}>
        <ul style={{ padding: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: () => (
      <div
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          color: switchDotsColor(theme.palette.mode),
          background: switchDotsColor(theme.palette.mode),
          padding: "0px",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} style={{ marginBottom: "40px", textAlign: "center" }}>
      {reviews.map((review) => (
        <ReviewCard key={review.id} review={review} />
      ))}
    </Slider>
  );
};

export default ReviewSlider;
