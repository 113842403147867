import { useState } from "react";
import { Card, CardContent, CardMedia, ImageList } from "@mui/material";
import Modal from "./Modal";
import { productsImages } from "../../constans/productsImages";
import ImageDescription from "./ImageDescription";

import "./style.css";

const ImageGallery = () => {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item.src);
  };

  const handelRotationRight = () => {
    const totalLength = productsImages.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = productsImages[0].src;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = productsImages.filter((item) => {
      return productsImages.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].src;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = productsImages.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = productsImages[totalLength - 1].src;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = productsImages.filter((item) => {
      return productsImages.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].src;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  return (
    <>
      <ImageList
        sx={{
          mb: 8,
          mt: 8,
          gridTemplateColumns: "repeat(auto-fill, minmax(15rem, 1fr))!important",
        }}
        gap={20}
      >
        {productsImages.map((item, index) => (
          <Card key={index}>
            <CardMedia component="img" image={item.src} alt={item.description} onClick={() => handleClick(item, index)} />
            <CardContent>
              <ImageDescription image={item} />
            </CardContent>
          </Card>
        ))}
      </ImageList>
      {clickedImg && (
        <Modal
          clickedImg={clickedImg}
          handelRotationRight={handelRotationRight}
          setClickedImg={setClickedImg}
          handelRotationLeft={handelRotationLeft}
        />
      )}
    </>
  );
};

export default ImageGallery;
