import Grid from "@mui/material/Grid";
import { reviews } from "../../constans/reviews";
import ReviewCard from "./ReviewCard";

const ReviewList = () => (
  <Grid container spacing={2} mb={5} justifyContent="center">
    {reviews.map((review) => (
      <Grid key={review.id} item>
        <ReviewCard review={review} />
      </Grid>
    ))}
  </Grid>
);

export default ReviewList;
