import { useState, useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Layout from "./components/layout/Layout";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import Services from "./pages/services/Services";
import Products from "./pages/products/Products";
import Reviews from "./pages/reviews/Reviews";
import Order from "./pages/order/Order";
import NotFound from "./pages/notFound/NotFound";
import { ColorModeContext } from "./context/ColorModeContext";

const App = () => {
  const [mode, setMode] = useState("dark");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  let theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  theme = responsiveFontSizes(theme);

  return (
    <BrowserRouter>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout theme={theme}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="sluzby" element={<Services />} />
              <Route path="galeria" element={<Products />} />
              <Route path="recenzie" element={<Reviews />} />
              <Route path="o_nas" element={<About />} />
              <Route path="objednavka" element={<Order />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </BrowserRouter>
  );
};

export default App;
