import { Typography } from "@mui/material";
import React from "react";
import ImageGallery from "../../components/imageGallery/ImageGallery";
import InfinitySlider from "../../components/infinitySlider/InfinitySlider";

const Products = () => {
  return (
    <>
      <Typography variant="h4" align="center" mb={5}>
        Kde všade sa produkty 3D tlače využívajú
      </Typography>
      <InfinitySlider />
      <ImageGallery />
      <Typography variant="body2" align="center" mb={5}>
        Produkty zobrazené na fotkách niesú predmetom predaja a slúžia len na prezentáciu kvality ponúkaných služieb.
      </Typography>
    </>
  );
};

export default Products;
