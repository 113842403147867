import { useState } from "react";
import { AppBar, Box, IconButton, Toolbar, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logoLight from "../../assets/logo-light.png";
import logoDark from "../../assets/logo-dark.png";
import DarkModeIcon from "../darkModeIcon/DarkModeIcon";
import { NavLink, Link } from "react-router-dom";
import DrawerWrapper from "./DrawerWrapper";
import Drawer from "./Drawer";
import { navItems } from "../../constans/navItems";

const Navbar = ({ theme, enableDarkMode }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const activeStyle = {
    color: "red",
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" color="grey">
        <Toolbar>
          <Box style={{ height: "6vh" }} sx={{ flexGrow: 1 }} component={Link} to="/">
            <img src={theme.palette.mode === "dark" ? logoDark : logoLight} alt="Logo JakuTech" style={{ height: "100%" }} />
          </Box>
          <DarkModeIcon theme={theme} enableDarkMode={enableDarkMode} md={{ display: "none" }} />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.id}
                sx={{ color: "text.primary" }}
                component={NavLink}
                to={item.path}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                {item.name}
              </Button>
            ))}

            <Button component={NavLink} to="objednavka" variant="contained" color="error" style={{ marginLeft: "1rem" }}>
              Nezáväzná požiadavka
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <DrawerWrapper mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}>
          <Drawer handleDrawerToggle={handleDrawerToggle} theme={theme} navItems={navItems} />
        </DrawerWrapper>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
};

export default Navbar;
