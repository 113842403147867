import Slider from "react-slick";
import SliderIcon from "./SliderIcon";
import { productsUsage } from "../../constans/productsUsage";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const InfinitySlider = () => {
  const settings = {
    arrows: false,
    centerMode: true,
    dots: false,
    draggable: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {productsUsage.map((product) => (
        <SliderIcon key={product.id} icon={product.icon} description={product.description} />
      ))}
    </Slider>
  );
};

export default InfinitySlider;
