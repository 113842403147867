import { NavLink } from "react-router-dom";
import services_3D_print from "../assets/services_3D_print.jpeg";
import services_3D_scan from "../assets/services_3D_scan.jpeg";
import services_our_products from "../assets/services_our_products.jpeg";

export const services = [
  {
    id: 1,
    heading: "3D Tlač",
    text: "Pri tlači využívame technológie FFF/FDM a SLA/DLP.",
    buttonText: "Objednať",
    image: services_3D_print,
    chip: false,
    chipText: "",
    description: {
      first: `Metóda FFF/FDM je najpoužívanejšia a najrozšírenejšia 
              technológia 3D tlače a to najmä kvôli jej cenovej dostupnosti. 
              Pre túto metódu výroby je charakteristické tavenie struny väčšinou 
              v podobe termoplastu, ktorá je podávaná do trysky, kde jednotlivým 
              nanášaním vrstiev na tlačovú podložku nám vzniká požadovaný produkt. 
              V závislosti od  výrobcov tlačiarni môžeme pri tejto metóde môžeme 
              hovoriť o výškach vrstvy v rozmedzí 0.05-0.35 mm. Kvalita tlače už závisí 
              od precíznosti a odladenia samotnej 3D tlačiarne!
              Medzi naše najpoužívanejšie materiály patria termoplasty PLA (Polylactic acid), 
              PETG (Polyethylene terephthalate glycol), ABS(Acrylonitrile butadiene styrene), 
              PC(Polycarbonate), Nylon.  Kompletný zoznam materiálov a ich farieb, ktoré máme 
              aktuálne v čase vašej požiadavky v ponuke, nájdete v sekcii nezáväzná požiadavka 
              pod zvolení tlače technológiou FDM.`,
      second: (
        <>
          Metóda SLA/DLP je druhá najpoužívanejšia technológia 3D tlače, pri ktorej princíp výroby ja založený báze vytvrdzovania
          reaktoplastov/živice za pomoci UV svetla. UV žiarenie je nastavené na určitú vlnovú dĺžku, ktorá je schopná meniť
          vlastnosti materiálu do pevnej podoby. Táto metóda je všeobecne známa svojou presnosťou a výsledným povrchom, ktorý vie
          byť vo výsledku veľmi hladký a konzistentný. Aj tu je výška vrstvy tlače podmienená výrobcom napr. v rozmedzí 0.025-0.1
          mm a kvalita precíznosťou nastavení tlačiarne. Ak by sme hovorili o materiáloch, SLA/DLP metóda ponúka napríklad
          transparentne, flexibilne a ale aj pevné materiály. Kompletný zoznam materiálov a ich farieb, ktoré máme aktuálne v čase
          vašej požiadavky v ponuke, nájdete v sekcii nezáväzná požiadavka pod zvolení tlače technológiou SLA. Pre lepšie získanie
          obrazu a pozorovanie rozdielov medzi jednotlivými metódami tlače nahliadnite do našej{" "}
          <NavLink to="/galeria" style={{ fontWeight: "bold", color: "red", textDecoration: "none" }}>
            galérie
          </NavLink>
          , kde nájdete detailne fotografie (vrátane popisu) nami tlačených predmetov.
        </>
      ),
    },
  },
  {
    id: 2,
    heading: "3D Skenovanie",
    text: "Kedy uvažovať nad využitím 3D skenovania? ",
    buttonText: "Objednať",
    image: services_3D_scan,
    chip: false,
    chipText: "Pripravujeme",
    description: {
      first: `V prípadoch ak nemáme možnosť pracovať s pôvodnou 
              dokumentáciou alebo pôvodnými súbormi je čas využiť 
              reverzné inžinierstvo. To spočíva v zbere dát za pomoci 
              kamery/skeneru, a následnej digitalizácii  zozbieraných dát. 
              Po prenose dostávame zoskupenia bodov/referencii, ktoré 
              vytvárajú obraz skenovaného predmetu. V prípade, že sa jedná 
              o malé tvarovo jednoduché súčiastky, u ktorých je požadované 
              ich priamo duplikovať pomocou 3D tlačiarni je proces pomerne 
              jednoduchý. Ak je ale požiadavka na skenovanie tvarovo zložitejších 
              predmetov, je možné, že bude potrebný postprocessing v podobe 
              modelovania resp. dodatočného upravovania oblasti modelu, ktoré 
              nebolo možné so 100% presnosťou skenovať.`,
      second: null,
    },
  },
  {
    id: 3,
    heading: "Naše produkty",
    text: "Rôznorodý obsah priamo z našej produkcie.",
    buttonText: "Objednať",
    image: services_our_products,
    chip: true,
    chipText: "Pripravujeme",
    description: {
      first: null,
      second: null,
    },
  },
];
