import React from "react";
import logoLight from "../../assets/logo-light.png";
import logoDark from "../../assets/logo-dark.png";
import { NavLink } from "react-router-dom";
import { Button, Box, Divider } from "@mui/material";

const Drawer = ({ handleDrawerToggle, theme, navItems }) => {
  return (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box style={{ width: "inherit" }} sx={{ my: 2 }}>
        <img src={theme.palette.mode === "dark" ? logoDark : logoLight} alt="Logo JakuTech" style={{ width: "80%" }} />
      </Box>
      <Divider />
      <Box style={{ display: "flex", flexDirection: "column" }}>
        {navItems.map((item) => (
          <Button
            key={item.id}
            to={item.path}
            style={{ color: theme.palette.text.primary, marginTop: "1rem" }}
            component={NavLink}
          >
            {item.name}
          </Button>
        ))}
        <Button component={NavLink} to="objednavka" variant="contained" color="error" style={{ margin: "1rem 0.5rem" }}>
          Nezáväzná požiadavka
        </Button>
      </Box>
    </Box>
  );
};

export default Drawer;
