import { Stack, Typography } from "@mui/material";
import SliderIcon from "../../components/infinitySlider/SliderIcon";
import { orderSteps } from "../../constans/orderSteps";
import OrderForm from "../../components/orderForm/OrderForm";

const Order = () => {
  return (
    <>
      <Typography variant="h4" mb={5} align="center">
        3 Jednoduché kroky
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={5} justifyContent="center" mb={5}>
        {orderSteps.map((step) => (
          <SliderIcon description={step.description} icon={step.icon} key={step.id} />
        ))}
      </Stack>
      <Typography align="center" mb={5}>
        Ak si nieste istý alebo neviete aký materiál a spôsob tlače zvoliť, pokojne nevyplňte nič. Po odoslaní formulára vám
        zavoláme a celý proces si detailne prejdeme. Nahrať môžete súbory vo formátoch{" "}
        <strong>.jpg .jpeg .png .step .stl .obj</strong>
      </Typography>
      <OrderForm />
    </>
  );
};

export default Order;
