import React from "react";
import { Drawer } from "@mui/material";

const drawerWidth = 240;

const DrawerWrapper = ({ mobileOpen, handleDrawerToggle, children }) => (
  <Drawer
    anchor="right"
    variant="temporary"
    open={mobileOpen}
    onClose={handleDrawerToggle}
    ModalProps={{
      keepMounted: true, // Better open performance on mobile.
    }}
    sx={{
      display: { md: "block", xl: "none" },
      "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
    }}
  >
    {children}
  </Drawer>
);

export default DrawerWrapper;
