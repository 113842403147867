export const defaultValues = {
  printOption: "",
  material: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  streetName: "",
  streetNumber: "",
  postalCode: "",
  city: "",
  notes: "",
  createdAt: new Date().toLocaleString(),
};
