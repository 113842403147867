import { Alert, Box, Button, CircularProgress, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./validationSchema";
import * as material from "../../constans/orderForm";
import { defaultValues } from "./defaultValues";
import FormField from "./FormField";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

const OrderForm = () => {
  const {
    register,
    handleSubmit,
    control,
    setError,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });

  const reRef = useRef();

  const watchPrintOption = watch("printOption");

  const formStyle = {
    direction: { xs: "column", sm: "row" },
    spacing: 3,
    justifyContent: "center",
    mb: 3,
  };

  const onSubmit = async (data) => {
    const token = await reRef.current.executeAsync();
    reRef.current.reset();

    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("email", data.email);
    formData.append("streetName", data.streetName);
    formData.append("streetNumber", data.streetNumber);
    formData.append("city", data.city);
    formData.append("postalCode", data.postalCode);
    formData.append("createdAt", data.createdAt);
    formData.append("notes", data.notes);
    formData.append("printOption", data.printOption);
    formData.append("material", data.material);
    formData.append("token", token);
    for (let i = 0; i < data.files.length; i++) {
      formData.append(`file${i}`, data.files[i]);
    }

    try {
      await axios.post("https://jakutech.sk/api/send", formData);
    } catch (error) {
      if (!!error.response.data?.email) {
        setError("files", { type: "server", message: error.response.data.email });
        return;
      }

      if (!!error.response.data.recaptchaError) {
        setError("recaptcha", { type: "server", message: error.response.data.recaptchaError });
        return;
      }

      setError("server", { type: "server", message: error.response.data.message });
    }
  };

  const renderOptions = () => {
    switch (watchPrintOption) {
      case "FDM":
        return material.options1.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ));
      case "SLA":
        return material.options2.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ));
      case "":
        return material.options3.map((option) => (
          <MenuItem value={option.value} key={option.value} disabled>
            {option.label}
          </MenuItem>
        ));
      default:
        return <MenuItem></MenuItem>;
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} textAlign="center" mb={5}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={5} justifyContent="center" mb={5}>
        <Controller
          name="printOption"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              select
              label="Služby"
              value={value}
              helperText="Ak si nie ste istý nechajte prázdne pole"
              onChange={onChange}
            >
              <MenuItem value="FDM">3D tlač - FDM</MenuItem>
              <MenuItem value="SLA">3D tlač - SLA</MenuItem>
              <MenuItem value="3D_SCAN">3D Skenovanie</MenuItem>
              <MenuItem value="">Zrušiť výber</MenuItem>
            </TextField>
          )}
        />
        <Controller
          name="material"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                select
                label="Materiál"
                helperText="Ak si nie ste istý nechajte prázdne pole"
                value={value}
                onChange={onChange}
                disabled={watchPrintOption === "" || watchPrintOption === "3D_SCAN"}
              >
                {renderOptions()}
              </TextField>
            );
          }}
        />
        <Box>
          <TextField
            inputProps={{
              type: "file",
              multiple: true,
              accept: ".png, .jpeg, .jpg, .igs, .step, .stl",
              name: "files",
              id: "files",
            }}
            helperText="Súbory nesmú presiahnuť 10MB"
            {...register("files")}
          />
          {errors.files !== undefined && (
            <Alert variant="filled" severity="error">
              {errors.files?.message}
            </Alert>
          )}
        </Box>
      </Stack>

      <Stack {...formStyle}>
        <FormField name="firstName" control={control} label="Krstné meno" error={errors.firstName} />
        <FormField name="lastName" control={control} label="Priezvisko" error={errors.lastName} />
      </Stack>
      <Stack {...formStyle}>
        <FormField name="email" control={control} label="Email" error={errors.email} />
        <FormField name="phoneNumber" control={control} label="Telefónne číslo" error={errors.phoneNumber} type="number" />
      </Stack>
      <Stack {...formStyle}>
        <FormField name="streetName" control={control} label="Názov ulice" error={errors.streetName} />
        <FormField name="streetNumber" control={control} label="Číslo ulice" error={errors.streetNumber} type="number" />
      </Stack>
      <Stack {...formStyle}>
        <FormField name="postalCode" control={control} label="PSČ" error={errors.postalCode} type="number" />
        <FormField name="city" control={control} label="Mesto" error={errors.city} />
      </Stack>
      <Box sx={{ width: { sm: "418px" }, margin: "30px auto" }}>
        <FormField name="notes" control={control} label="Poznámka" error={errors.notes} multiline rows={5} fullWidth />
        <Typography variant="caption">{material.noteDescription}</Typography>
      </Box>

      <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA} size="invisible" ref={reRef} />

      {errors.recaptcha && (
        <Box sx={{ width: { sm: "418px" }, margin: "30px auto" }}>
          <Alert variant="filled" severity="error">
            {errors.recaptcha?.message}
          </Alert>
        </Box>
      )}

      {errors.server && (
        <Box sx={{ width: { sm: "418px" }, margin: "30px auto" }}>
          <Alert variant="filled" severity="error">
            {errors.server?.message}
          </Alert>
        </Box>
      )}
      {isSubmitSuccessful ? (
        <Stack {...formStyle}>
          <Alert variant="filled" severity="success" align="center">
            Objednávka bola úspešne odoslaná. Budeme vás kontaktovať.
          </Alert>
        </Stack>
      ) : (
        <Button
          variant="contained"
          color="error"
          type="submit"
          align="center"
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          {isSubmitting ? "Objednávka sa odosiela...." : "Odoslať"}
        </Button>
      )}
    </Box>
  );
};

export default OrderForm;
