import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const FormField = ({ name, control, label, error, type = "text", multiline, rows, fullWidth }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <TextField
        label={label}
        value={value}
        onChange={onChange}
        error={Boolean(error)}
        helperText={error?.message}
        type={type}
        multiline={multiline}
        rows={rows}
        fullWidth={fullWidth}
      />
    )}
  />
);

export default FormField;
