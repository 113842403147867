import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";

const Modal = ({ clickedImg, setClickedImg, handelRotationRight, handelRotationLeft }) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
    }
  };

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        <img src={clickedImg} alt="bigger pic" />
        <div onClick={handelRotationLeft} className="overlay-arrows_left">
          <ArrowCircleLeft />
        </div>
        <div onClick={handelRotationRight} className="overlay-arrows_right">
          <ArrowCircleRight />
        </div>
      </div>
    </>
  );
};

export default Modal;
